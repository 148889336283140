import sliderCompsToPackages from './components/Slider/compsToPackages';
import timePickerCompsToPackages from './components/TimePicker/compsToPackages';
import addressInputCompsToPackages from './components/AddressInput/compsToPackages';
import stylableLineCompsToPackages from './components/StylableLine/compsToPackages';
//import baseUIToggleSwitchCompsToPackages from './baseUI/components/BaseUIToggleSwitch/compsToPackages';
import ratingsInputCompsToPackages from './components/RatingsInput/compsToPackages';

export default Object.assign({},
  sliderCompsToPackages,
  timePickerCompsToPackages,
  addressInputCompsToPackages,
  stylableLineCompsToPackages,
  //baseUIToggleSwitchCompsToPackages,
  ratingsInputCompsToPackages
);
